import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { flushSync } from 'react-dom'

import {
  primaryButtonClass,
  secondaryButtonClass,
} from '../../constants/classConstants'
import { useAuth } from '../../contexts/AuthProvider'
import { usePatient } from '../../contexts/PatientProvider'
import type { Patient } from '../../types/Patient'
import { ONBOARDING_STEP } from '../../types/Patient'
import type { InterestOption } from './WhatBringsYouHere'
import trackMixPanel, { MIXPANEL_EVENT } from '../../hooks/useMixPanel'
import useRanOutOfBulkSessions from '../../hooks/useRanOutOfBulkSessions'

const AddAnotherPerson: React.FC = () => {
  const { user } = useAuth()
  const navigate = useNavigate()
  const { setPatient, patient } = usePatient()
  const ranOufOfBulk = useRanOutOfBulkSessions()

  const isInterestedInTherapy: boolean =
    user.data.whatBringsYouHere.some(
      (o: InterestOption) => o?.name === 'Therapy'
    ) ||
    user.roster.some(
      (p) =>
        Boolean(p.onboardingStep?.length) &&
        (p.isSeparateLogin
          ? p.allowedToBook && p.ableToBookAndCancel
          : p.allowedToBook)
    )
  const iepPatientsInOnboarding: boolean = user.roster.some(
    (p: Patient) => Boolean(p?.onboardingStep?.length) && p?.isIepOnly
  )

  const addAnotherPerson = () => {
    trackMixPanel({
      eventName: MIXPANEL_EVENT.ONBOARDING_STEP_COMPLETED,
      properties: {
        step: ONBOARDING_STEP.addAnotherPerson,
        patient: patient?.firstName + ' ' + patient?.lastName,
        additionalUser: true,
      },
    })

    setPatient({ relationship: null })
    navigate('/onboarding/care-for', {
      state: { from: '/onboarding/add-another-person' },
    })
  }

  const goNext = () => {
    trackMixPanel({
      eventName: MIXPANEL_EVENT.ONBOARDING_STEP_COMPLETED,
      properties: {
        step: ONBOARDING_STEP.addAnotherPerson,
        patient: patient?.firstName + ' ' + patient?.lastName,
        additionalUser: false,
      },
    })

    if ((!isInterestedInTherapy && !iepPatientsInOnboarding) || ranOufOfBulk)
      return navigate('/dashboard')

    if (
      user.roster.every(
        (p) => p?.onboardingStep === ONBOARDING_STEP.skippedInErrorDob
      )
    )
      return navigate('/onboarding/thank-you-sign-out')

    const nextPatient = user.roster.find(
      (p: Patient) =>
        !p?.carePlans?.some((cp) => !cp.isIep && cp?.sessions?.length) &&
        p?.onboardingStep === ONBOARDING_STEP.booking
    )

    if (!nextPatient) return navigate('/dashboard')

    flushSync(() => {
      setPatient(nextPatient)

      const patientsInBooking = user.roster.filter(
        (p: Patient) => p?.onboardingStep === ONBOARDING_STEP.booking
      )

      // if only IEP patients OR patients without allowed to book
      if (
        patientsInBooking.every(
          (p: Patient) => p?.isIepOnly || !p?.allowedToBook
        )
      )
        navigate('/dashboard')
      // default behaviour
      else
        navigate(
          `/select-service-line?multipleOnboarding=${
            patientsInBooking?.length > 1
          }`
        )
    })
  }

  useEffect(() => {
    if (!user) return

    const accountHolderIsBelow16 = user?.roster?.some(
      (patient: Patient) =>
        patient?.age < 16 && patient?.relationship?.key === 'myself'
    )

    if (accountHolderIsBelow16) goNext()
  }, [user])

  return (
    <div className="max-w-lg">
      {/* Texts */}
      <p className="text-center text-2xl font-bold xs:text-lg">
        Add another person?
      </p>

      {/* Submit */}
      <div className="grid w-full grid-cols-2 gap-2 sm:gap-4">
        <button
          className={secondaryButtonClass}
          type="button"
          onClick={addAnotherPerson}
        >
          Yes
        </button>
        <button type="button" className={primaryButtonClass} onClick={goNext}>
          No
        </button>
      </div>
    </div>
  )
}

export default AddAnotherPerson
